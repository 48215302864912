import { useSpring, animated } from '@react-spring/web'
import { routeMap } from '/routeMap'
import { useMediaQuery } from '@kaliber/use-media-query'
import { HeadingSlantedLarge } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlue, ButtonLinkDark } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { ImageCover } from '/features/buildingBlocks/Image'
import { easeInOutQuart } from '/machinery/motion'
import linePlaceholder from '/images/lines/line-content-hero.raw.svg'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function Hero({ image = undefined, title, introduction, buttons }) {
  const viewportIsMd = useMediaQuery(mediaStyles.viewportMd)

  const animatedClipPath = useSpring({
    from: {
      '--animated-clipping-point-left-top-x': '0%',
      '--animated-clipping-point-left-top-y': '100%',
      '--animated-clipping-point-right-top-x': '50%',
      '--animated-clipping-point-right-top-y': '100%'
    },
    to: {
      '--animated-clipping-point-left-top-x': '55%',
      '--animated-clipping-point-left-top-y': '0%',
      '--animated-clipping-point-right-top-x': '100%',
      '--animated-clipping-point-right-top-y': '0%'
    },
    config: {
      easing: easeInOutQuart,
      duration: 500
    }
  })

  return (
    <header className={styles.component}>
      <div className={styles.layout}>

        <Icon icon={linePlaceholder} layoutClassName={styles.lineHeroLeft} />
        {image?.asset ?
          <animated.div style={animatedClipPath} className={styles.image}>
            <ImageCover
              {...{ image }}
              aspectRatio={1 / 1}
              layoutClassName={styles.coverImage}
              sizes='min(768px, calc(100vw - 40px))'
            />
          </animated.div>
          : <Icon icon={linePlaceholder} layoutClassName={styles.imagePlaceholder} />
        }
        <div className={styles.content}>
          <HeadingSlantedLarge
            layoutClassName={styles.title}
            {...{ title }}
            h={1}
          />
          {introduction && (
            <p className={styles.description}>
              {introduction}
            </p>
          )}
          <div className={styles.buttons}>
            {buttons && buttons.map((x, index) => {
              const ButtonLink = index === 0 ? ButtonLinkBlue : ButtonLinkDark
              return (
                <div key={x.link._id} className={styles.button}>
                  <ButtonLink
                    dataX='cta'
                    href={x.link && determineDocumentPathSync({ document: x.link, routeMap })}
                  >
                    {x.title}
                  </ButtonLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </header>
  )
}
